@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.radius {
  &__error-message {
    margin: 0;
    padding: 0;
    color: var(--floating-label-invalid-color, var(--color-foreground-attention));

    .typography-small();
  }

  &__radio-field {
    padding: 18px 0;
    border-bottom: 1px solid var(--color-neutral-2);
  }

  &__radio-field:last-child {
    border: 0;
    padding-bottom: 0;
  }
}