@import './common.less';

.ctas {
  position: fixed;
  background-color: var(--color-neutral-0);
  bottom: env(safe-area-inset-bottom);
  padding: 16px 0;
  width: ~"calc(100vw - 32px)";

  button, a {
    margin-bottom: 16px;
  }
}
