@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.parts-installation {
  .introduction {
    &__top-content {
      height: 240px;
      background-color: #44CCD5;

      img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .ctas {
    button.btn--primary {
      margin-bottom: 16px;
    }
  }
}
