@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-warranty-spoke-m-v2 {
  padding: 0.25rem 1rem 0.25rem 1rem;

  .vas-spoke-header {
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #eee;
    flex-direction: row-reverse;
    &-text {
      flex: 1 1 auto;
      font-size: 1.125rem;
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0.5rem;
      white-space: nowrap;
      color: #000000;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: transparent;

      svg.icon.icon--close-16 {
        fill: #555;
      }
    }
  }

  .vas-spoke-header.vas-vault-spoke-header {
    border-bottom: unset;
    margin-top: 8px;
  }

  .vas-spoke-header-text.vault-spoke-header-text {
    text-align: unset;
    margin: auto
  }

  .vas-messsage {
    // override component style
    background-color: #f1f8fe;
    margin: 20px 0 28px 0;
  }
  .vas-item {
    .vas-item-checkbox-v2 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 32px 0 0 0;

      .vas-item-labels label {
        display: block;
        span:nth-child(2) {
          float: right;
          text-align: right;
        }
      }

      .vas-item-labels div {
        display: flex;
        justify-content: space-between;
        label {
          display: block;
          span:nth-child(2) {
            float: right;
            text-align: right;
          }
        }
      }

      .vas-item-label:nth-child(n + 2) {
        margin-top: 4px;
      }

      .warranty-learn-more {
        margin-top: 0.25rem;
        a {
          color: #3665f3;
          text-decoration: underline;
          font-size: 14px;
        }
      }
      .vas-hidden {
        display: block;
      }

      .vas-item-level-sub--block {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .vas-item-level-sub--sec {
        font-size: 14px;
        line-height: 20px;
        color: #111820;
      }

      .vas-checkbox {
        display: inline-flex;
        position: relative;
        vertical-align: text-bottom;
        margin: 4px 16px 0 0;
      }

      .checkbox__control[type='checkbox'] {
        height: 18px;
        min-width: 18px;
        width: 18px;
      }
      .checkbox--large .checkbox__control[type='checkbox'] {
        height: 24px;
        min-width: 24px;
        width: 24px;
      }
      span.checkbox__icon {
        display: -webkit-inline-box;
        display: inline-flex;
        height: 18px;
        outline-offset: 1px;
      }
      .checkbox--large span.checkbox__icon {
        height: 24px;
      }
      span.checkbox__icon[hidden] {
        display: -webkit-inline-box;
        display: inline-flex;
      }
      svg.checkbox__checked {
        color: #3665f3;
        color: var(--checkbox-checked-color, #3665f3);
      }
      svg.checkbox__unchecked {
        color: #ccc;
        color: var(--checkbox-unchecked-color, #ccc);
      }
      input.checkbox__control[type='checkbox'] {
        font-size: 100%;
        margin: 0;
        opacity: 1;
        padding: 0;
        position: absolute;
        z-index: 1;
      }
      .vas-checkbox svg {
        display: inline-block;
        fill: currentColor;
        pointer-events: none;
        stroke: currentColor;
        stroke-width: 0;
        vertical-align: middle;
        height: 18px;
        width: 18px;
      }
      .checkbox--large svg {
        height: 24px;
        width: 24px;
      }
      input.checkbox__control[type='checkbox'] + span.checkbox__icon svg.checkbox__checked {
        display: none;
      }
      input.checkbox__control[type='checkbox'] + span.checkbox__icon svg.checkbox__unchecked {
        display: inline-block;
      }
      input.checkbox__control[type='checkbox']:checked + span.checkbox__icon svg.checkbox__checked {
        display: inline-block;
      }
      input.checkbox__control[type='checkbox']:checked + span.checkbox__icon svg.checkbox__unchecked {
        display: none;
      }
      input.checkbox__control[type='checkbox'][disabled] + span.checkbox__icon {
        opacity: 0;
      }
      input.checkbox__control[type='checkbox'][disabled] + span.checkbox__icon svg {
        fill: #c7c7c7;
      }
      input.checkbox__control[type='checkbox']:focus + span.checkbox__icon {
        outline: 1px auto #707070;
        outline-offset: 2px;
      }
      input.checkbox__control[type='checkbox']:focus:not(:focus-visible) + span.checkbox__icon {
        outline: none;
      }
      @media (prefers-color-scheme: dark) {
        .skin-experiment-dark-mode .vas-checkbox {
          --checkbox-checked-color: #5192ff;
          --checkbox-unchecked-color: #ccc;
        }
      }
    }
  }

  .BOLD,
  .bold {
    // from exp svc
    font-weight: bold;
  }

  .vas-heading {
    margin-top: @spacing-200;
    display: flex;
    align-items: center;
    font-size: @font-size-18;
  }

  .icon-vault {
    background-image: url(https://ir.ebaystatic.com/cr/v/c01/swc-updated-sprite-0223.png);
    background-position: center;
    background-position-y: -427px;
    height: 25px;
    width: @spacing-300;
    display: inline-block;
    background-size: 44px;
    margin: 0;
  }

  .vas-label {
    margin-top: @spacing-200;
    font-size: @font-size-14;
  }

  .vas-secondary-labels {
    margin-top: @spacing-200;
    font-size: @font-size-14;
  }

  .spoke-body-primary {
    display: flex;
    font-size: @font-size-14;
  }

  .spoke-body-label {
    margin-bottom: @spacing-200;
    margin-top: @spacing-200;
    font-size: @font-size-14;
  }

  .vas-spoke-body-primary-left {
    flex: 1 1 auto;
  }

  .vas-spoke-footer {
    font-size: @font-size-12;
    .vas-spoke-item-dataItems a {
      color: var(--color-blue-4);
    }
  }

  .vas-spoke-tertiary-title {
    margin-top: @spacing-200;
  }

  .vas-header-text {
    margin-left: @spacing-50;
  }

  .vas-spoke-title {
    margin-bottom: @spacing-200;
  }

  .vault-divider {
    border-top: 1px solid var(--color-neutral-2);
    border-bottom: 1px solid var(--color-neutral-2);
    margin-bottom: @spacing-200;
    margin-top: @spacing-200;
  }

  .heading-text {
    margin-left: @spacing-50;
    font-size: @font-size-14;
  }

  .learn-more-text {
    margin-top: @spacing-100;
    font-size: @font-size-14;
    a {
      color: var(--color-blue-4);
    }
  }

  .high-asp {
    margin-bottom: @spacing-400;
    .section-notice {
      display: block;
      .section-notice__header {
        height: auto;
      }
    }
  }
}
