@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-tire-installation {
  padding: 0;

  .header-link {
    .typography-regular();
  }

  .header-title {
    .typography-medium-bold();
  }

  &--installer-title {
    display: flex;
    justify-content: space-between;
  }

  &__scrollable {
    // 265px includes the Header + Paddings + CTAS
    height: ~"calc(100vh - 211px)";
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 16px;
  }

  &__map {
    .vas-header {
      z-index: 5;
      position: relative;
      margin: 10px;
    }

    button.vas-tire-installation__postal-code-button {
      width: 90px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
    }
  }

  .find-installer-redesign {

    .whats-included {
      border-bottom: 0;
      margin: 0 0;
    }

    .details__label, .details__icon {
      color: var(--color-neutral-8);
    }

    .whats-included__details {

      .details__label {
        .typography-regular();
      }

      .details__summary {
        justify-content: inherit;
      }

      ul {
        .typography-small();
        margin-left: 10px;
      }
    }
  }

  .find-installer-choose {
    h2 {
      .typography-medium();
    }

    button.fake-link {
      display: block;
      text-decoration: auto;

      .ux-textspans--PSEUDOLINK {
        padding: 5px 5px;
      }
    }
  }

  &.vas-tire-installation__installation_v2 {
    .ux-textspans--PSEUDOLINK {
      color: var(--color-neutral-7);
    }
  }
}
