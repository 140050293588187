@import '~@ebay/skin/tokens.css';

.sort {
  text-align: right;

  button.sort__icon {
    min-width: 100px;
    height: 30px;
    background-color: transparent;

    svg {
      padding-right: 5px;
    }
  }

  &__drawer-body {
    text-align: left;

    div.listbox__option[role="option"] {
      padding: 8px 0;
    }
  }
}
