@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.find-installer {

  &__installation-label {
    margin: 0;
    padding: 0;
    .typography-regular();
  }

  .drawer-dialog__title {
    .typography-medium();
  }

  .drawer-dialog__close {
    width: 32px;
    height: 32px;
  }

  .drawer-dialog__main {
    padding-bottom: 40px;

    .find-installer-radius {
      .typography-regular();

      &__error-message {
        margin: 0;
        padding: 0;
        color: var(--floating-label-invalid-color, var(--color-foreground-attention));
        .typography-small();
      }

      &__radio-field {
        padding: 18px 0;
        border-bottom: 1px solid var(--color-neutral-2);

        .ux-textspans--BOLD {
          padding-left: 10px;
        }
      }

      &__radio-field:last-child {
        border: 0;
        padding-bottom: 0;
      }
    }
  }
}
