.find-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &__main {
    padding-bottom: 15px;
  }

  &__installation-label {
    margin: 0;
    padding: 0;
  }

  &__drawer-body {
    padding-right: 16px
  }

  .drawer-dialog__window {
    max-height: 80%;
  }
}
